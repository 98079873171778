<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card color="grey lighten-4">
      <v-card-title class="headline white"
        >{{ isEditing ? "Edit" : "Add a new" }} Proposal</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey-lighten-4">
        <v-form @submit.prevent="saveForm" method="post">
          <v-card elevation="1" outlined class="mb-5">
            <v-card-title>Basic Information</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Tour Title"
                v-model="fields.name"
                type="text"
                outlined
                :error="errors.hasOwnProperty('name')"
                :error-messages="errors['name']"
              ></v-text-field>
              <v-select
                label="Lead Customer"
                v-model="fields.lead_contact_id"
                :items="customers"
                item-value="id"
                item-text="full_name"
                outlined
                ref="customerSelect"
                :placeholder="fields.is_new_customer ? 'New Customer' : null"
                :error="errors.hasOwnProperty('lead_contact_id')"
                :error-messages="errors['lead_contact_id']"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleIsNewCustomer">
                    <v-list-item-action>
                      <v-icon>{{
                        fields.is_new_customer
                          ? "mdi-checkbox-marked"
                          : "mdi-checkbox-blank-outline"
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>New Customer</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                  <div class="pa-3">
                    <v-text-field
                      label="Search Customers"
                      type="text"
                      v-model="customerSearchTerm"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
              <v-card
                outlined
                elevation="1"
                class="mb-7"
                v-if="fields.is_new_customer"
              >
                <v-card-subtitle class="grey lighten-5"
                  >Create A New Customer</v-card-subtitle
                >
                <v-divider></v-divider>
                <v-card-text class="grey lighten-5">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="First Name"
                        v-model="fields.customer.first_name"
                        type="text"
                        outlined
                        :error="errors.hasOwnProperty('customer.first_name')"
                        :error-messages="errors['customer.first_name']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Last Name"
                        v-model="fields.customer.last_name"
                        type="text"
                        outlined
                        :error="errors.hasOwnProperty('customer.last_name')"
                        :error-messages="errors['customer.last_name']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    label="Email Address"
                    v-model="fields.customer.email"
                    type="email"
                    outlined
                    :error="errors.hasOwnProperty('customer.email')"
                    :error-messages="errors['customer.email']"
                  ></v-text-field>
                </v-card-text>
              </v-card>
              <v-text-field
                label="Number of People"
                v-model="fields.total_golfers"
                type="number"
                outlined
                :error="errors.hasOwnProperty('total_golfers')"
                :error-messages="errors['total_golfers']"
              ></v-text-field>
              <v-checkbox
                label="Agent Led"
                v-model="fields.agent_led"
                class="mt-0"
              ></v-checkbox>
              <v-autocomplete
                label="Agent"
                v-model="fields.agent_id"
                :items="agents"
                item-value="id"
                item-text="name"
                outlined
                v-if="fields.agent_led"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
          <v-card outlined elevation="1">
            <v-card-title>Currency</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Client's Currency"
                v-model="fields.client_currency"
                type="text"
                outlined
                :error="errors.hasOwnProperty('client_currency')"
                :error-messages="errors['client_currency']"
              ></v-text-field>
              <v-text-field
                label="Client's Currency Symbol"
                v-model="fields.currency_symbol"
                type="text"
                outlined
                :error="errors.hasOwnProperty('currency_symbol')"
                :error-messages="errors['currency_symbol']"
              ></v-text-field>
              <v-text-field
                label="Exchange Rate"
                v-model="fields.exchange_rate"
                outlined
                :error="errors.hasOwnProperty('exchange_rate')"
                :error-messages="errors['exchange_rate']"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="white">
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn color="accent" text @click="saveForm" :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: false,
      default: null,
    },

    isEditing: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      customerSearchTerm: "",
      fields: {
        name: "",
        lead_contact_id: null,
        is_new_customer: false,
        customer: {
          first_name: null,
          last_name: null,
          email: null,
        },
        total_golfers: null,
        agent_led: false,
        agent_id: null,
        client_currency: null,
        currency_symbol: null,
        exchange_rate: null,
      },
      errors: {
        customer: {},
      },
    };
  },

  computed: {
    customers() {
      let customers = this.$store.state.drum.customers["customers"];
      if (this.customerSearchTerm !== "") {
        customers = customers.filter(c => {
          const name = c.full_name.toLowerCase();
          const email = c.email ? c.email.toLowerCase() : "";
          const searchTerm = this.customerSearchTerm.toLowerCase();
          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return customers;
    },

    customerId() {
      return this.fields.customer_id;
    },

    isNewCustomer() {
      return this.fields.is_new_customer;
    },

    agents() {
      return this.$store.state.drum.admin["agents"];
    },

    tour() {
      const tour = this.$store.state.drum.tours["tour"];

      return Object.keys(tour).length === 0 ? null : tour;
    },

    agentLed() {
      return this.fields.agent_led;
    },
  },

  watch: {
    customerId(customerId) {
      if (customerId !== null && this.fields.is_new_customer === true) {
        this.fields.is_new_customer = false;
      }
    },
    isNewCustomer(isNewCustomer) {
      if (isNewCustomer === true && this.fields.customer_id !== null) {
        this.fields.customer_id = null;
      }
    },
    agentLed(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.fields.agent_id = null;
      }
    },
  },

  methods: {
    openForm: function() {
      if (this.customer) {
        this.fields.lead_contact_id = this.customer.id;
      }

      if (this.isEditing) {
        this.fields.name = this.tour.name;
        this.fields.lead_contact_id = this.tour.lead_contact_id;
        this.fields.total_golfers = this.tour.total_golfers;
        this.fields.client_currency = this.tour.client_currency;
        this.fields.currency_symbol = this.tour.currency_symbol;
        this.fields.exchange_rate = this.tour.exchange_rate;
        this.fields.agent_id = this.tour.agent_id;
        this.fields.agent_led = this.tour.agent_id !== null;
      }

      this.dialog = true;
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        name: "",
        lead_contact_id: null,
        is_new_customer: false,
        customer: {
          first_name: null,
          last_name: null,
          email: null,
        },
        total_golfers: null,
        agent_led: false,
        agent_id: null,
        client_currency: null,
        currency_symbol: null,
        exchange_rate: null,
      };
      this.errors = {
        customer: {},
      };
    },

    toggleIsNewCustomer: function() {
      this.fields.is_new_customer = !this.fields.is_new_customer;
      this.$refs.customerSelect.blur();
    },

    saveForm: function() {
      this.loading = true;
      this.errors = {
        customer: {},
      };

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.tourId = this.$route.params.tourId;
      }

      this.$store
        .dispatch("drum/tours/saveTour", payload)
        .then(resp => {
          this.resetForm();
          if (this.isEditing === false) {
            this.$router.push({
              name: "module-drum-proposals-individual",
              params: {
                tourId: resp.data.tour.id,
              },
            });
          }
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
